import * as React from "react"
import Navbar from './Navbar'



export default function Layout({children}) {
  return (

<div class="container">
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous" />
    <Navbar />
    <div className="content">
        { children }
    </div>
    <br/>
    <footer class="d-flex flex-wrap justify-content-center py-3 mb-4 border-top">
        <p>Copyright 2022 - Cloud Focus, LLC</p>
    </footer>




</div>




  )
}
