import React from 'react'
import { Link } from 'gatsby'



export default function Navbar() {
  return (

    <nav>
        <header class="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
        <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <img class="bi me-2" alt="Cloud Focus Technology" src="../images/OriginalLogoSymbolCropped.png" width="69" height="40" />
        <span class="fs-4">Cloud Focus Technology</span>
        </a>

        <ul class="nav nav-pills">
            <li class="nav-item"><Link to='/' className="nav-link active">Home</Link></li>
            <li class="nav-item"><Link to="/services" className="nav-link">Services</Link></li>
            <li class="nav-item"><Link to="/blog" className="nav-link">Blog</Link></li>
            <li class="nav-item"><Link to='/about' className="nav-link">About</Link></li>
        </ul>
        </header>

    </nav>
  )
}
